import React, { useEffect } from "react";
import { motion, useAnimation, useReducedMotion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Box({
  children,
  transitionOverrides,
  hiddenStyles,
  visibleStyles,
  triggerOnce = true,
  ...rest
}) {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: triggerOnce });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const boxVariants = {
    hidden: { opacity: 0, y: 0, ...hiddenStyles },
    visible: {
      opacity: 1,
      y: 0,
      ...visibleStyles,
      transition: {
        ease: [0.1, 0.25, 0.3, 1],
        duration: 0.6,
        delay: 0,
        ...transitionOverrides,
      },
    },
  };
  return (
    <motion.div
      ref={ref}
      className="Box"
      initial={useReducedMotion() ? "visible" : "hidden"}
      exit={useReducedMotion() ? "visible" : "hidden"}
      animate={controls}
      variants={boxVariants}
      {...rest}
    >
      {children}
    </motion.div>
  );
}
